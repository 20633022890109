// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AuthConfig } from '../auth_config';
const isBrowser = () => typeof window !== 'undefined';
export const environment = {
  auth: {
    authorizationParams: {
      audience: AuthConfig.dev.audience,
      redirect_uri: isBrowser() ? window.location.origin : '',
    },
    scope: AuthConfig.dev.scope,
    clientId: AuthConfig.dev.clientId,
    domain: AuthConfig.dev.domain,
    clientTimeoutMilliseconds: 1000 * 60 * 60 * 24, //how many milliseconds of inactivity before the client times out.
    x_api_key: 'c94GYzVMA31VTfwp00e1p6ewEqWkMJjT9CX6q9d5',
  },
  activityThrottleTimeMilliseconds: 30000, //time between "activity detected emissions". Used to not clog the redux extension.
  activityCountdownTimerSeconds: 15, //for how many seconds will the user see the "are you there" dialog before automatic logout.
  description: 'development',
  development: true,
  domain: 'https://dev.mykingston.ca',
  feedbackUrl: 'https://surveys.delighted.com/c/u/MGtPQhbJ',
  google_maps_api_key:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAscI0bwWRa_C9k_QEzWf5Fnjr50KCnvlA&libraries=places,visualization&language=en',
  home: 'https://dev.mykingston.ca',
  maxServicesOnHomePage: 4,
  myTax_link: 'https://myTax.randomaccess.ca',
  openDataEndpoint: 'https://opendatakingston.cityofkingston.ca',
  osvcSSOlink: `https://${AuthConfig.dev.domain}/samlp/${AuthConfig.dev.osvc_clientId}?RelayState=https%3A%2F%2Fcityofkingston--tst1.custhelp.com%2Fapp%2Fask`,
  osvcSSOlinkBase: `https://${AuthConfig.dev.domain}/samlp/${AuthConfig.dev.osvc_clientId}?RelayState=https%3A%2F%2Fcityofkingston--tst1.custhelp.com`,
  osvcSSOAccountQuestionsUrl: `https://${AuthConfig.dev.domain}/samlp/${AuthConfig.dev.osvc_clientId}?RelayState=https%3A%2F%2Fcityofkingston--tst1.custhelp.com%2Fapp%2Faccount/questions`,
  osvcCategoryMap: {
    mfapGeneral: 'c/3124',
    mfapLostCard: 'c/3122',
    missedCollection: 'c/2751',
    firePermit: 'c/2712',
  },
  osvcAnswersMap: {
    parkingPermits: '/app/answers/detail/a_id/629',
  },
  production: false,
  reconnectInterval: 2000,
  sentry: {
    dsn: 'https://622c57656a424b7186424e122864590a@o1214346.ingest.sentry.io/6354829',
    tracesSampleRate: 1.0,
  },
  serverUrl: AuthConfig.dev.serverUrl,
  serverWebSocketUrl: AuthConfig.dev.serverWebSocketUrl,
  test: false,
  osvcHost: 'https://cityofkingston--tst1.custhelp.com',
  version: '3ced3c3f241d9d50c0163fd59469d4515e8efe6f', //bitbucket pipeline replaces this value with commit id. Used for sentry release
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
