import { createAction, props } from '@ngrx/store';
import { CustomerSubscriptions } from '../../customer.model';

export const loadCustomerSubscriptions = createAction('[Customer Subscriptions] Load Subscriptions');

export const loadCustomerSubscriptionsSuccess = createAction(
  '[Customer Subscriptions] Load Subscriptions Success',
  props<{ subscriptions: CustomerSubscriptions }>()
);

export const loadCustomerSubscriptionsFailure = createAction(
  '[Customer Subscriptions] Load Subscriptions Failure',
  props<{ error: any }>()
);

export const updateCustomerSubscription = createAction(
  '[Customer Subscriptions] Update Subscription',
  props<{ subscription: CustomerSubscriptions }>()
);

export const updateCustomerSubscriptionSuccess = createAction(
  '[Customer Subscriptions] Update Subscription Success',
  props<{ subscription: CustomerSubscriptions; message: string }>()
);

export const updateCustomerSubscriptionFailure = createAction(
  '[Customer Subscriptions] Update Subscription Failure',
  props<{ error: any }>()
);
