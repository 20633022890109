import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as AuthActions from '../../../auth/store/auth.actions';
import * as fromApp from '../../../store/app.reducer';
import { Customer, CustomerAddress, CustomerEmail, CustomerFamilyMember, CustomerPhone } from '../../customer.model';
import { CustomerService } from '../../customer.service';
import * as CustomerAddressActions from '../actions/customer-address.actions';
import * as CustomerEmailActions from '../actions/customer-email.actions';
import * as CustomerFamilyActions from '../actions/customer-family.actions';
import * as CustomerPhoneActions from '../actions/customer-phone.actions';
import * as CustomerSubscriptionActions from '../actions/customer-subscriptions.actions';
import * as CustomerActions from '../actions/customer.actions';
import { selectCustomerFamilyTemp } from '../selectors/customer-family.selector';
import { selectCustomerPreferences } from '../selectors/customer.selector';
import { MatSnackBar } from '@angular/material/snack-bar';

const handleError = (errorRes: any, errType: string) => {
  console.log(errType);
  console.log(errorRes);
  let errorMessage = errorRes.error.errorMessage || 'An unknown error occurred!';
  let errCode = errorRes.error.errorCode || false;
  console.log('errorRes', errorRes, 'error code:', errCode);
  if (errorRes.status == 500) {
    errorMessage =
      'Sorry, something went wrong with the MyKingston server. Wait a few moments then try refreshing the page';
  }

  switch (errType) {
    case 'phone': {
      errorMessage =
        errorRes.error.errorMessage ||
        'An unknown error occurred with your phone number! We are working on a solution.';
      return of(CustomerPhoneActions.customerPhoneFailed({ payload: errorMessage }));
    }
    case 'email':
      switch (errCode) {
        case 'ERROR_EMAIL_DOES_NOT_BELONG_TO_CUSTOMER_CRM':
        case 'ERROR_EMAIL_EXISTS_IN_CRM':
          errorMessage = 'A contact with this email already exists and we are unable to merge the records.';
          break;
        case 'ERROR_EMAIL_ALREADY_EXISTS':
          errorMessage = 'This email address is already in use and cannot be added as a secondary email.';
          break;
        case 'ERROR_VERIFICATION_EMAIL_NOT_SENT':
          errorMessage = 'There was a problem sending a verification email. Try again shortly.';
          break;
        case 'ERROR_VERIFICATION_CODE_EXPIRED':
          errorMessage = 'The verification code you entered is expired. Use the button below to send a new code.';
          break;
        case 'ERROR_DATABASE_OP':
          errorMessage = 'There was a problem performing this operation. Try again shortly';
          break;
        case 'ERROR_EMAIL_DOES_NOT_BELONG_TO_CUSTOMER_CRM':
          errorMessage =
            'The email you tried to add belongs to another account. If you believe this is wrong, go to the home page and submit a request';
          break;

        default:
          break;
      }
      return of(CustomerEmailActions.customerEmailsFailed({ payload: errorMessage }));

    case 'address': {
      errorMessage =
        errorRes.error.errorMessage || 'An unknown error occurred with your address! We are working on a solution.';
      return of(CustomerAddressActions.customerAddressFailed({ payload: errorMessage }));
    }
    case 'family':
      return of(CustomerFamilyActions.customerFamilyFailed({ payload: errorMessage }));

    case 'customer':
      return of(
        CustomerActions.customerFailed({
          payload: errorMessage,
        })
      );
    default:
      break;
  }
  return of(
    CustomerActions.customerFailed({
      payload: errorMessage,
    })
  );
};

@Injectable()
export class CustomerEffects {
  private actions$ = inject(Actions);
  private customerService = inject(CustomerService);
  private store$ = inject<Store<fromApp.AppState>>(Store);
  private dialog = inject(MatDialog);
  private snackBar = inject(MatSnackBar);

  fetchCustomerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginComplete),
      switchMap(() => {
        return this.customerService.fetchCustomer().pipe(
          map((customerProfile) => {
            if (!customerProfile) {
              return { type: 'DUMMY STATE - CUSTOMER PROFILE NOT FOUND' };
            } else {
              return CustomerActions.setCustomer({
                payload: customerProfile,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'customer');
          })
        );
      })
    )
  );
  fetchCustomerPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginComplete),
      switchMap(() => {
        return this.customerService.fetchCustomerPreferences().pipe(
          map((customerPreferences) => {
            return CustomerActions.setCustomerPreferencesFromApi({
              payload: customerPreferences,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'customer');
          })
        );
      })
    )
  );

  saveCustomerPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.saveCustomerPreferences, CustomerActions.patchCustomerPreferences),
      withLatestFrom(this.store$.select(selectCustomerPreferences)),
      switchMap(([_customerAction, customerPreferences]) => {
        return this.customerService.saveCustomerPreferences(customerPreferences).pipe(
          map((customerPreferences) => {
            if (!customerPreferences) {
              return { type: 'DUMMY STATE - CUSTOMER PREFERENCES NOT SAVED' };
            } else {
              // return CustomerActions.setCustomerPreferences({
              //   payload: customerPreferences,
              // });
              return { type: 'DUMMY STATE - CUSTOMER PREFERENCES SAVED' };
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'customer');
          })
        );
      })
    )
  );

  fetchCustomerAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerAddressActions.fetchCustomerAddresses, CustomerAddressActions.deleteCustomerAddressSuccess),
      switchMap(() => {
        return this.customerService.fetchCustomerAddresses().pipe(
          map((customerAddress) => {
            //customer with no addresses returns empty array []
            if (!customerAddress) {
              return { type: 'DUMMY STATE - CUSTOMER ADDRESSES NOT FOUND' };
            } else {
              return CustomerAddressActions.setCustomerAddresses({
                payload: customerAddress,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'address');
          })
        );
      })
    )
  );
  fetchCustomerPhones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerPhoneActions.fetchCustomerPhones,
        CustomerPhoneActions.updateCustomerPhoneSuccess,
        CustomerPhoneActions.addCustomerPhoneSuccess,
        CustomerPhoneActions.deleteCustomerPhoneSuccess
      ),
      switchMap(() => {
        return this.customerService.fetchCustomerPhones().pipe(
          map((customerPhones) => {
            if (!customerPhones) {
              return { type: 'DUMMY STATE - CUSTOMER PHONES NOT FOUND' };
            } else {
              return CustomerPhoneActions.setCustomerPhones({
                payload: customerPhones,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'phone');
          })
        );
      })
    )
  );
  fetchCustomerFamily$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerFamilyActions.fetchCustomerFamily),
      switchMap(() => {
        return this.customerService.fetchCustomerFamily().pipe(
          map((customerFamily) => {
            if (!customerFamily) {
              return { type: 'DUMMY STATE - CUSTOMER FAMILY NOT FOUND' };
            } else {
              return CustomerFamilyActions.setCustomerFamily({
                payload: customerFamily,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'family');
          })
        );
      })
    )
  );

  updateCustomerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerAddressActions.startUpdateCustomerAddress),

      switchMap((customerAction) => {
        return this.customerService.updateCustomerAddress(customerAction.payload).pipe(
          map((customer: CustomerAddress) => {
            console.log('update customer address returned successfully');
            return CustomerAddressActions.updateCustomerAddressSuccess({
              payload: customer,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'address');
          })
        );
      })
    )
  );
  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.startUpdateCustomer),
      switchMap((customerAction) => {
        return this.customerService.updateCustomer(customerAction.payload).pipe(
          map((customer: Customer) => {
            return CustomerActions.updateCustomerSuccess({
              payload: customer,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'customer');
          })
        );
      })
    )
  );

  createCustomerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerAddressActions.startAddNewCustomerAddress),
      switchMap((customerAction) => {
        return this.customerService.createCustomerAddress(customerAction.payload).pipe(
          map((customer: CustomerAddress) => {
            console.log('add customer address returned successfully');
            return CustomerAddressActions.addCustomerAddressSuccess({
              payload: customer,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'address');
          })
        );
      })
    )
  );
  storeCustomerFamilyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerFamilyActions.addNewCustomerFamilyMember,
        CustomerFamilyActions.deleteCustomerFamilyMember,
        CustomerFamilyActions.updateCustomerFamilyMember
      ),
      withLatestFrom(this.store$.select(selectCustomerFamilyTemp)),
      switchMap(([_customerAction, customerFamily]) => {
        return this.customerService.storeCustomerFamilyMember(customerFamily).pipe(
          map((customerFamily: CustomerFamilyMember[]) => {
            return CustomerFamilyActions.setCustomerFamily({
              payload: customerFamily,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'family');
          })
        );
      })
    )
  );

  deleteCustomerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerAddressActions.startDeleteCustomerAddress),

      switchMap((customerAction) => {
        return this.customerService.deleteCustomerAddress(customerAction.payload).pipe(
          map(() => {
            return CustomerAddressActions.deleteCustomerAddressSuccess();
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'address');
          })
        );
      })
    )
  );
  deleteCustomerPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerPhoneActions.startDeleteCustomerPhone),
      switchMap((customerAction) => {
        return this.customerService.deleteCustomerPhone(customerAction.payload).pipe(
          map(() => {
            return CustomerPhoneActions.deleteCustomerPhoneSuccess();
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'phone');
          })
        );
      })
    )
  );
  updateCustomerPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerPhoneActions.startUpdateCustomerPhone),
      switchMap((customerAction) => {
        return this.customerService.updateCustomerPhone(customerAction.payload).pipe(
          map((phone: CustomerPhone) => {
            return CustomerPhoneActions.updateCustomerPhoneSuccess({
              payload: phone,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'phone');
          })
        );
      })
    )
  );

  createCustomerPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerPhoneActions.startAddNewCustomerPhone),
      switchMap((customerAction) => {
        return this.customerService.createCustomerPhone(customerAction.payload).pipe(
          map((phone: CustomerPhone) => {
            return CustomerPhoneActions.addCustomerPhoneSuccess({
              payload: phone,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'phone');
          })
        );
      })
    )
  );
  createCustomerEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.addNewCustomerEmail),
      switchMap((customerEmailAction) => {
        return this.customerService.createCustomerEmail(customerEmailAction.payload).pipe(
          map((email: CustomerEmail) => {
            // return CustomerEmailActions.addNewCustomerEmailSuccess({
            //   payload: email,
            // });
            return CustomerEmailActions.sendCustomerEmailVerification({
              payload: email,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );
  deleteCustomerEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.deleteCustomerEmail),
      switchMap((customerAction) => {
        return this.customerService.deleteCustomerEmail(customerAction.payload).pipe(
          map(() => {
            return CustomerEmailActions.deleteCustomerEmailSuccess({
              payload: customerAction.payload,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );

  updateCustomerEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.updateCustomerEmailVerification),
      switchMap((customerAction) => {
        return this.customerService.updateCustomerEmail(customerAction.payload).pipe(
          map((email: CustomerEmail) => {
            if (email.isVerified)
              return CustomerEmailActions.updateCustomerEmailVerificationSuccess({ payload: customerAction.payload });
            else {
              return CustomerEmailActions.updateCustomerEmailVerificationFailed({ payload: customerAction.payload });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );

  updateCustomerEmailPrimary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.updateCustomerEmailPrimary),
      switchMap((customerAction) => {
        return this.customerService.updateCustomerEmail(customerAction.payload).pipe(
          map((email: CustomerEmail) => {
            if (email.isPrimary)
              return CustomerEmailActions.updateCustomerEmailPrimarySuccess({
                payload: customerAction.payload,
              });
            else {
              return CustomerEmailActions.updateCustomerEmailPrimaryFailed({
                payload: customerAction.payload,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );
  resendCustomerEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.resendCustomerEmailVerification),
      switchMap((customerAction) => {
        return this.customerService.resendCustomerEmailVerification(customerAction.payload).pipe(
          map(() => {
            return CustomerEmailActions.resendCustomerEmailVerificationSuccess({ payload: customerAction.payload });
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );
  fetchCustomerEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerEmailActions.fetchCustomerEmails, CustomerEmailActions.updateCustomerEmailPrimarySuccess),
      switchMap(() => {
        return this.customerService.fetchCustomerEmails().pipe(
          map((customerEmail) => {
            if (!customerEmail) {
              return { type: 'DUMMY STATE - CUSTOMER EMAILS NOT FOUND' };
            } else {
              return CustomerEmailActions.setCustomerEmails({
                payload: customerEmail,
              });
            }
          }),
          catchError((errorRes) => {
            return handleError(errorRes, 'email');
          })
        );
      })
    )
  );

  fetchCustomerSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerSubscriptionActions.loadCustomerSubscriptions),
      switchMap(() => {
        return this.customerService.fetchCustomerSubscriptions().pipe(
          map((subscriptions) => {
            return CustomerSubscriptionActions.loadCustomerSubscriptionsSuccess({ subscriptions });
          }),
          catchError((errorRes) => {
            console.error('Error occurred while fetching subscriptions:', errorRes);
            return of(
              CustomerSubscriptionActions.loadCustomerSubscriptionsFailure({ error: 'Failed to load subscriptions' })
            );
          })
        );
      })
    )
  );

  updateCustomerSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerSubscriptionActions.updateCustomerSubscription),
      switchMap((action) => {
        return this.customerService.updateCustomerSubscriptions(action.subscription).pipe(
          map((updatedSubscription) => {
            return CustomerSubscriptionActions.updateCustomerSubscriptionSuccess({
              subscription: updatedSubscription,
              message: 'Changes saved!',
            });
          }),
          catchError((errorRes) => {
            console.error('Error occurred while updating subscriptions:', errorRes);
            return of(
              CustomerSubscriptionActions.updateCustomerSubscriptionFailure({ error: 'Failed to update subscriptions' })
            );
          })
        );
      })
    )
  );

  successSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerSubscriptionActions.updateCustomerSubscriptionSuccess),
        tap((action) => {
          this.snackBar.open(action.message, 'Dismiss', {
            duration: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  hideCustomerDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerAddressActions.updateCustomerAddressSuccess,
        CustomerAddressActions.addCustomerAddressSuccess,
        CustomerAddressActions.customerAddressFailed,
        CustomerPhoneActions.addCustomerPhoneSuccess,
        CustomerPhoneActions.updateCustomerPhoneSuccess,
        CustomerPhoneActions.customerPhoneFailed,
        CustomerFamilyActions.setCustomerFamily,
        CustomerActions.updateCustomerSuccess
      ),
      map(() => {
        console.log('about to close dialog');
        return CustomerActions.closeCustomerDialog();
      })
    )
  );
  closeCustomerDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.closeCustomerDialog),
        tap(() => {
          console.log('dialog should be closed');

          this.dialog.closeAll();
        })
      ),
    { dispatch: false }
  );
}
